// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
  }
  
  .contacts-container a {
    margin-left: 1rem;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .contacts-container a:hover {
    transform: scale(1.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;IACrB,+BAA+B;EACjC;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".contacts-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 1.25rem;\n  }\n  \n  .contacts-container a {\n    margin-left: 1rem;\n    display: inline-block;\n    transition: transform 0.3s ease;\n  }\n  \n  .contacts-container a:hover {\n    transform: scale(1.1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
