import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faYoutube, faReact, faPython, faJsSquare } from '@fortawesome/free-brands-svg-icons';

const ProjectCard = ({ project }) => {
  const renderMedia = (path) => {
    if (/\.(mov|mp4)$/i.test(path)) {
      return <video src={path} alt={project.title} style={{ height: 'auto', width: '100%', objectFit: 'contain' }} controls />;
    } else {
      return <img src={path} alt={project.title} style={{ height: 'auto', width: '100%', objectFit: 'contain' }} />;
    }
  };

  const renderStack = (stack) => {
    return stack.split(', ').map((tech, index) => {
      switch(tech) {
        case "Python":
          return <FontAwesomeIcon key={index} icon={faPython} style={{ marginRight: '5px', color: 'blue', fontSize: '1.5rem' }} />;
        case "React":
          return <FontAwesomeIcon key={index} icon={faReact} style={{ marginRight: '5px', color: 'cyan', fontSize: '1.5rem' }} />;
        case "Typescript":
          return <FontAwesomeIcon key={index} icon={faJsSquare} style={{ marginRight: '5px', color: '#007acc', fontSize: '1.5rem' }} />;
        default:
          return <span key={index}>{tech}{index < stack.split(', ').length - 1 ? ', ' : ''}</span>;
      }
    });
  };
	
	return (
    <div style={{ width: '44%', boxSizing: 'border-box', marginLeft: '2rem', marginTop: '1rem', marginBottom: '-1rem', color: 'grey', backgroundColor: '#000000' }}>
      <h3 style={{ fontSize: '1.3rem', color: '#ff0000' }}>{project.title}</h3>
      {renderMedia(project.image)}
      <p><strong>Stack:</strong> {renderStack(project.stack)}</p>
      <p style={{ marginTop: '-10px' }}><strong>Description:</strong> {project.description}</p>
      <p style={{ marginTop: '-10px' }}>
        <strong>GitHub:</strong>
        <a href={project.link} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '10px', color: 'pink', fontSize: '1.5rem' }} />
        </a>
      </p>
      {project.showcase && (
        <p style={{ marginTop: '-10px' }}>
          <strong>Showcase:</strong>
          <a href={project.showcase} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} style={{ marginLeft: '10px', color: 'pink', fontSize: '1.5rem' }} />
          </a>
        </p>
      )}
    </div>
  );
};

export default ProjectCard;

