import React from 'react';
import ProjectCard from './ProjectCard.jsx';

// Mock project data
const projectsGH = [
  {
    id: 1,
    title: 'net-sec-toolkit',
    // image: 'net-sec-toolkit.png',
    image: 'flood1.mp4',
    description: 'Flooding remote PC with well known DOS attack - SYN flood, cracking hashes in reverse hash feature or remote pc"s brute force credentials SSH attacking, all of these stuff straight from the web-based emulated terminal and more..',
    stack: 'C, Shell, React, Typescript, Python',
    link: 'https://t.ly/UntFF',
    showcase: 'https://t.ly/8-LQf'
  },
	  {
    id: 2,
    title: 'dict_attack__g',
    image: 'dict_attack_ig_optimized.png',
    description: 'dict_attack__g is a .py script designed to perform a dictionary attack on login, including IP manipulation and bypassing CAPTCHA, by using a provided wordlist and a username/email',
    stack: 'python',
    link: 'https://t.ly/-_qj0',
    showcase: 'https://t.ly/RoRdj'
  },
  {
    id: 3,
    title: 'key-stream-logger',
    image: 'keylogger-dark.png',
    description: '🚧 WIP 🚧 the Key-Stream Logger is a background process written in C and utilizing Quartz Event Services on macOS (ARM arch), this app monitors user keystrokes and transmits them to a remote server in a file format, this can be considered as a kind of MALWARE',
    stack: 'C',
    link: 'https://shorturl.at/7w8fG',
    showcase: ''
  },
  {
    id: 4,
    title: 'metasnap',
    image: 'metasnap.png',
    description: 'Very simple .py script downloads image from a specified URL and extracts EXIF metadata if available',
    stack: 'Python',
    link: 'https://t.ly/zxyaL',
    showcase: ''
  },
  {
    id: 5,
    title: 'brute_dirs.py',
    image: 'brute-dirs.png',
    description: 'A python script designed to inspect and display the structure of directories and files starting from a user-specified root directory. It provides a hierarchical view of folders and files',
    stack: 'Python',
    link: 'https://t.ly/jbao5',
    showcase: ''
  },
];

export const Projects = () => {
  return (
    <>
      <h2 style={{ width: '100%', height: 'auto', textAlign: 'center', marginTop: '2.2rem' }}>GH projects</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', overflow: 'auto', height: '80vh' }}>
        {projectsGH.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </>
  );
};
