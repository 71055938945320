import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AboutPage } from './pages/About/About.jsx';
import HomePage from './pages/HomePage/Home';
import LoginModal from './components/LoginForm/LoginForm';
import TypingText from './components/Introductory/TypingText';
import { Projects } from './pages/Projects/Project';
import './App.css';

const App = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [showGif, setShowGif] = useState(false);
  const [showBlackScreen, setShowBlackScreen] = useState(false);
  const [showWalkingGif, setShowWalkingGif] = useState(false);
  const [gifOpacity, setGifOpacity] = useState(1);

  useEffect(() => {
    if (showGif) {
      setTimeout(() => {
        setShowBlackScreen(true);
        setShowGif(false);
        setTimeout(() => {
          setShowWalkingGif(true);
          setTimeout(() => {
            setShowWalkingGif(false);
            setShowBlackScreen(false);
          }, 9500);
        }, 1);
      }, 4500);
    }
  }, [showGif]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleGuestAccess = () => {
    setShowGif(true);
    setTimeout(() => setShowGif(false), 5000);
  };

  return (
    <Router>
      <div>
        {isModalOpen ? (
          <div className="main-page">
            <TypingText phrase="- welcome folks, here you can check out what ive been up to, grab my CV, peep my skills, hit up my GitHub projects, or connect on linkedIn or gitHub" />
            <LoginModal onClose={handleClose} onGuestAccess={handleGuestAccess} />
          </div>
        ) : (
          <nav>
            <ul>
              <li><Link to="/home">home</Link></li>
              <li><Link to="/about">about me</Link></li>
              <li><Link to="/projects">GH projects</Link></li>
            </ul>
          </nav>
        )}

        {showGif && (
          <div style={{ opacity: gifOpacity, transition: 'opacity 0.5s ease-out', position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1000' }}>
            <img src="/grains.gif" alt="Loading..." style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        )}

        {showBlackScreen && (
          <div style={{ backgroundColor: 'black', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1001' }}>
            {showWalkingGif && (
              <img src="/walking.gif" alt="Walking..." style={{ position: 'absolute', left: '10%', animation: 'moveRight 10s linear', transform: 'scale(2)' }} />
            )}
            <p className="blinking-text">Loading...</p>
          </div>
        )}


        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
