// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cv {
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    display: inline-block;
    color: grey;
    margin-right: -10px !important;
}

.cv .cv-icon {
    font-size: 2rem;
    transition: transform 0.3s ease, color 0.3s ease;
}

.cv .cv-icon:hover {
    transform: scale(1.3);
    color: darkgrey;
}`, "",{"version":3,"sources":["webpack://./src/components/CV/CV.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,qBAAqB;IACrB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gDAAgD;AACpD;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".cv {\n    padding: 1rem;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin-top: 2rem;\n    display: inline-block;\n    color: grey;\n    margin-right: -10px !important;\n}\n\n.cv .cv-icon {\n    font-size: 2rem;\n    transition: transform 0.3s ease, color 0.3s ease;\n}\n\n.cv .cv-icon:hover {\n    transform: scale(1.3);\n    color: darkgrey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
