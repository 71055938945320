// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.top-right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 0;
    right: 0;
    margin: 1rem;
}

.about-text {
    margin-top: 1rem;
    margin-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;IACf,MAAM;IACN,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".about-page-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100%;\n}\n\n.top-right-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    position: fixed;\n    top: 0;\n    right: 0;\n    margin: 1rem;\n}\n\n.about-text {\n    margin-top: 1rem;\n    margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
