// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinking-cursor {
  font-weight: 100;
  font-size: 24px;
  color: white;
  animation: 1s blink step-end infinite;
}

@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Introductory/TypingText.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,qCAAqC;AACvC;;AAEA;;EAEE;;IAEE,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".blinking-cursor {\n  font-weight: 100;\n  font-size: 24px;\n  color: white;\n  animation: 1s blink step-end infinite;\n}\n\n@keyframes blink {\n\n  from,\n  to {\n    color: transparent;\n  }\n\n  50% {\n    color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
