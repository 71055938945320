// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #00ff00;
  }  

  .close-button {
    background-color: #333333;
    color: #00ff00;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
  }
  
  .close-button:hover {
    background-color: black;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoginForm/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modal-content {\n    background-color: #000000;\n    padding: 20px;\n    border-radius: 10px;\n    border: 2px solid #00ff00;\n  }  \n\n  .close-button {\n    background-color: #333333;\n    color: #00ff00;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 14px;\n    display: block;\n    margin: 0 auto;\n  }\n  \n  .close-button:hover {\n    background-color: black;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
