// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* Set default font family */
  -webkit-font-smoothing: antialiased; /* Improve the rendering of fonts on webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* Improve the rendering of fonts on Mozilla Firefox for Mac OS X */
  background-color: #000; /* Set background color to black */
  color: #0f0; /* Set text color to green */
}

.container {
  width: 100vw; /* Set width to 100% of the viewport width */
  height: 100vh; /* Set height to 100% of the viewport height */
  overflow: hidden; /* Hide any content that overflows the container */
  position: relative; /* Set position context for absolute positioning inside */
  font-family: 'Consolas', 'Menlo', monospace; /* Set a monospaced font for the hacker-like appearance */
  font-size: 16px; /* Set font size, adjust as needed */
  color: #0f0; /* Set text color to green */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS,EAAE,0BAA0B;EACrC,UAAU,EAAE,2BAA2B;EACvC;;cAEY,EAAE,4BAA4B;EAC1C,mCAAmC,EAAE,sDAAsD;EAC3F,kCAAkC,EAAE,mEAAmE;EACvG,sBAAsB,EAAE,kCAAkC;EAC1D,WAAW,EAAE,4BAA4B;AAC3C;;AAEA;EACE,YAAY,EAAE,4CAA4C;EAC1D,aAAa,EAAE,8CAA8C;EAC7D,gBAAgB,EAAE,kDAAkD;EACpE,kBAAkB,EAAE,yDAAyD;EAC7E,2CAA2C,EAAE,yDAAyD;EACtG,eAAe,EAAE,oCAAoC;EACrD,WAAW,EAAE,4BAA4B;AAC3C","sourcesContent":["body {\n  margin: 0; /* Remove default margin */\n  padding: 0; /* Remove default padding */\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; /* Set default font family */\n  -webkit-font-smoothing: antialiased; /* Improve the rendering of fonts on webkit browsers */\n  -moz-osx-font-smoothing: grayscale; /* Improve the rendering of fonts on Mozilla Firefox for Mac OS X */\n  background-color: #000; /* Set background color to black */\n  color: #0f0; /* Set text color to green */\n}\n\n.container {\n  width: 100vw; /* Set width to 100% of the viewport width */\n  height: 100vh; /* Set height to 100% of the viewport height */\n  overflow: hidden; /* Hide any content that overflows the container */\n  position: relative; /* Set position context for absolute positioning inside */\n  font-family: 'Consolas', 'Menlo', monospace; /* Set a monospaced font for the hacker-like appearance */\n  font-size: 16px; /* Set font size, adjust as needed */\n  color: #0f0; /* Set text color to green */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
